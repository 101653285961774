<template>
  <div class="confirmation-page__container">
    <div class="confirmation-page__icon">
      <SvgImage
        height="48"
        icon="Icon_validate_green"
        width="48"
      />
    </div>

    <ContentBlocks
      :identifiers="['page-confirmation-content']"
      :title="false"
      class="confirmation-page__text"
    />
  </div>
</template>

<script>
import SvgImage from "@/components/General/SvgImage";
import ContentBlocks from "@/components/ContentBlocks";

export default {
  name: "RegisterConfirmation",
  components: { ContentBlocks, SvgImage },
  middleware: 'is-guest'
}
</script>

<style lang="scss" scoped>
@import '@/modules/customer/pages/styles/register-confirmation.scss';
</style>
